<template>
  <div style="text-align: center;padding-top: 50px;">
    <van-loading size="24px">用户授权中...</van-loading>
  </div>
</template>
<script>
import './index.less'
import { getQueryString, getQueryString2 } from '@/utils/utils'
import { Loading, Toast } from 'vant'
export default {
  components: {
    [Loading.name]: Loading,
    [Toast.name]: Toast
  },
  data () {
    return {
      code: '',
      TOKEN: ''
    }
  },
  mounted () {
    this.getCode()
  },
  methods: {
    // 授权获取code
    getCode () {
      const that = this
      var local = 'http://kinderGather.whsedu.cn/#/AuthorityForCode'
      var appid = 'wx0826afb089d6465d'
      this.code = getQueryString('code')
      if (!this.code || this.code == null || this.code === '') {
        window.localStorage.setItem('AssetsCode', getQueryString2('assetsCode'))
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(
          local
        )}&response_type=code&scope=snsapi_userinfo#wechat_redirect`
      } else {
        that.$axios
          .post('/api/WeChat/OAuthForAsset', {
            Code: this.code
          })
          .then(res => {
            if (res.code === 200) {
              // 需要得到的用户信息存起来，注册的时候会用到，未注册的调往注册
              const data = res.data
              window.localStorage.setItem('UserType', data.UserType)
              window.localStorage.setItem('TOKEN', data.Token)
              if (data.UserID) {
                window.localStorage.setItem('UserID', data.UserID)
              }
              const AssetsCode = window.localStorage.getItem('AssetsCode') || ''
              that.$router.push({
                path: 'RepairQcodeZc',
                query: {
                  id: AssetsCode
                }
              })
            } else {
              window.localStorage.clear()
              Toast.fail(res.data || res.msg || '请求失败')
            }
          })
      }
    }
  }
}
</script>
